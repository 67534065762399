<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">TenWeeks</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Customer Login</p>
          <p class="mb-2">Welcome to TenWeeks! 👋🏻</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <label for="MobileNo">Mobile No</label>
            <v-text-field
              v-model="form.mobileNo"
              outlined
              placeholder="9123123123"
              class="mb-3"
              required
              :disabled="stepNo == 2"
              :rules="mobileNoRules"
            ></v-text-field>
            <label for="otp" v-if="stepNo == 2">Enter OTP</label>
            <v-text-field
              v-model="form.otp"
              outlined
              placeholder="Enter 6 digit OTP"
              class="mb-3"
              required
              v-if="stepNo == 2"
              :disabled="stepNo == 1"
              :rules="otpRules"
            ></v-text-field>
            <v-row v-if="stepNo == 2">
              <v-col sm="6" xs="12">
                <v-btn :disabled="loading" class="ma-1" color="grey" x-small> Resend OTP </v-btn>
              </v-col>
              <v-col sm="6" xs="12" class="text-right">
                <v-btn :disabled="loading" x-small class="ma-1" color="primary">
                  <i>{{ icons.mdiPencil }}</i
                  >&nbsp;Edit Mobile no
                </v-btn>
              </v-col>
            </v-row>
            <v-btn
              block
              color="primary"
              v-if="stepNo == 1"
              v-on:click="sendOtp"
              class="mt-3"
              :loading="loading"
              :disabled="loading"
            >
              Proceed to send OTP
            </v-btn>

            <v-btn
              block
              color="primary"
              v-if="stepNo == 2"
              v-on:click="sendOtp"
              class="mt-3"
              :loading="loading"
              :disabled="loading"
            >
              Login
            </v-btn>

            <v-btn block type="link" :to="{ name: 'login' }" class="mt-6"> Go to Staff Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiPencil } from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import Util from '@/utils/axios-util.js'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const loading = ref(false)
    const form = reactive({
      mobileNo: '',
      otp: '',
    })
    const valid = ref(false)
    const stepNo = ref(1)
    const mobileNoRules = ref([
      v => !!v || 'Mobile No is required',
      v => (v && v.length > 9 && v.length < 11) || 'Please enter valid 10 digit mobile number.',
    ])
    const otpRules = ref([v => !!v || 'Invalid OTP', v => (v && v.length < 6) || 'Invalid OTP'])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      loading,
      form,
      mobileNoRules,
      otpRules,
      stepNo,
      valid,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiPencil,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'processing', 'loginError']),
  },
  methods: {
    ...mapActions(['login', 'externalLogin', 'signOut', 'clearSession']),
    sendOtp() {
      var e = this

      let flag = e.$refs.form.validate()

      if (flag) {
        e.loading = true
        e.stepNo = 2
        e.loading = false
        // let obj = {
        // 	mobileNo: e.form.mobileNo,
        // }
        // e.login(obj)
        // 	.then(function (response) {
        // 		let token = e.currentUser.token

        // 		Util.Axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : ''

        // 		e.$router.push({ name: 'dashboard' })
        // 	})
        // 	.catch(function (response) {
        // 		console.log(response)
        // 	})
        // 	.finally(function () {
        // 		e.loading = false
        // 	})
      }
    },
    validateOtp() {
      var e = this

      let flag = e.$refs.form.validate()

      if (flag) {
        e.loading = true
        e.stepNo = 2
        e.loading = false
        // let obj = {
        // 	mobileNo: e.form.mobileNo,
        // }
        // e.login(obj)
        // 	.then(function (response) {
        // 		let token = e.currentUser.token

        // 		Util.Axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : ''

        // 		e.$router.push({ name: 'dashboard' })
        // 	})
        // 	.catch(function (response) {
        // 		console.log(response)
        // 	})
        // 	.finally(function () {
        // 		e.loading = false
        // 	})
      }
    },
  },
  created() {
    this.clearSession()
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
